import { defineComponent } from 'vue';
import { GoogleMap, Marker as GmapsMarker, MarkerCluster } from 'vue3-google-map';
import { mapsStyle } from '@/helpers/GoogleMapsOptions';
import { DefaultRenderer } from '@googlemaps/markerclusterer';
import eventBus from '@/services/EventBus';
export default defineComponent({
    /** @see https://www.npmjs.com/package/vue3-google-map#marker-cluster */
    components: { GoogleMap, GmapsMarker, MarkerCluster },
    name: 'google-maps',
    props: {
        geoPoints: {
            type: Array,
            require: true,
        },
        renderer: {
            type: Object,
            default: new DefaultRenderer(),
        },
    },
    computed: {
        markerClusteroptions() {
            // build new stylish renderer here
            const clusterRenderer = this.renderer;
            return { renderer: clusterRenderer };
        },
        mapsStyle() {
            return mapsStyle;
        },
        defaultMarkerIcon() {
            var _a;
            if (((_a = this.geoPoints) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                return this.highlightedMarkerIcon;
            }
            return this.simpleMarkerIcon;
        },
        markerPoints() {
            const googlePoints = [];
            if (!this.geoPoints) {
                return [];
            }
            this.geoPoints.forEach((point) => {
                if (point.latitude !== null && point.longitude !== null) {
                    googlePoints.push({ lat: point.latitude, lng: point.longitude, id: point.id });
                }
            });
            return googlePoints;
        },
    },
    mounted() {
        this.trySetupLoadedListener();
        eventBus.on('googleMaps:mapLocationClicked', (locationId) => {
            if (!Number.isInteger(locationId)) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.moveToLocationId(locationId);
        });
    },
    data() {
        const color = '#123645';
        return {
            lastHighlightedLocationId: 0,
            key: 'AIzaSyCYs1PIonIGx3esVvulEF3GOxNpOwofB70',
            highlightedMarkerIcon: {
                url: 'data:image/svg+xml;base64,' +
                    window.btoa(`
                           <svg width="31" height="59" viewBox="0 0 31 59" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15.5" cy="43.5" r="15.5" fill="${color}" fill-opacity="0.15"/>
    <circle cx="15.5" cy="43.5" r="5.5" fill="${color}"/>
    <path d="M15.5 0C8.67725 0 3.125 5.59075 3.125 12.4644C3.125 22.231 14.3367 32.3152 14.8139 32.7387C15.0105 32.9134 15.2552 33 15.5 33C15.7447 33 15.9895 32.9134 16.1861 32.7401C16.6632 32.3152 27.875 22.231 27.875 12.4644C27.875 5.59075 22.3227 0 15.5 0ZM15.5 19.25C11.7091 19.25 8.625 16.1659 8.625 12.375C8.625 8.58412 11.7091 5.5 15.5 5.5C19.2909 5.5 22.375 8.58412 22.375 12.375C22.375 16.1659 19.2909 19.25 15.5 19.25Z"
          fill="${color}"/>
</svg>`),
                // eslint-disable-next-line no-undef
                scaledSize: this.getMap() ? new google.maps.Size(75, 75) : null,
            },
            simpleMarkerIcon: {
                url: 'data:image/svg+xml;base64,' +
                    window.btoa(`<svg width="31" height="59" viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15.5" cy="15.5" r="15.5" fill="${color}" fill-opacity="0.15"/>
    <circle cx="15.5" cy="15.5" r="5.5" fill="${color}"/>
</svg>`),
                // eslint-disable-next-line no-undef
                scaledSize: this.getMap() ? new google.maps.Size(75, 75) : null,
            },
        };
    },
    methods: {
        getMarkerForLocationId(locationId) {
            var _a;
            //Marker not found in types
            const proxy = this.$refs['marker-' + locationId];
            if (!proxy) {
                return null;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            return (_a = proxy[0].marker) !== null && _a !== void 0 ? _a : null;
        },
        moveToLocationId(id) {
            const map = this.getMap();
            if (null === map) {
                return;
            }
            let targetCoordinate = null;
            this.markerPoints.forEach((point) => {
                if (point.id === id) {
                    targetCoordinate = point;
                    return;
                }
            });
            if (!targetCoordinate) {
                return;
            }
            // eslint-disable-next-line no-undef
            map.setCenter(new google.maps.LatLng(targetCoordinate.lat, targetCoordinate.lng));
            map.setZoom(10);
            const marker = this.getMarkerForLocationId(id);
            if (marker) {
                marker.icon = this.highlightedMarkerIcon;
            }
            const oldMarker = this.getMarkerForLocationId(this.lastHighlightedLocationId);
            if (oldMarker) {
                oldMarker.icon = this.simpleMarkerIcon;
            }
            this.lastHighlightedLocationId = id;
            // reset method is protected... so we need to make a mess to trigger it properly
            // eslint-disable-next-line no-undef
            const fakeMarker = new google.maps.Marker({
                // eslint-disable-next-line no-undef
                position: new google.maps.LatLng(0, 0),
            });
            const cluster = this.getCluster();
            if (!cluster) {
                return;
            }
            cluster.addMarker(fakeMarker);
            cluster.removeMarker(fakeMarker);
        },
        sendClicked(id) {
            eventBus.emit('googleMaps:mapLocationClicked', id);
        },
        trySetupLoadedListener() {
            try {
                const map = this.getMap();
                if (map === null) {
                    setTimeout(() => this.trySetupLoadedListener(), 10);
                    return;
                }
                // eslint-disable-next-line no-undef
                google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
                    this.fitMap();
                });
                // eslint-disable-next-line no-undef
                google.maps.event.addListener(map, 'bounds_changed', () => {
                    this.broadcastLocationsInView();
                });
            }
            catch (error) {
                setTimeout(() => this.trySetupLoadedListener(), 10);
            }
        },
        // eslint-disable-next-line no-undef
        getMap() {
            var _a, _b;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return (_b = (_a = this.$refs.mapRef) === null || _a === void 0 ? void 0 : _a.map) !== null && _b !== void 0 ? _b : null;
        },
        getCluster() {
            var _a, _b;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            return (_b = (_a = this.$refs) === null || _a === void 0 ? void 0 : _a.clusterRef) === null || _b === void 0 ? void 0 : _b.markerCluster;
        },
        broadcastLocationsInView() {
            const map = this.getMap();
            if (map === null) {
                return;
            }
            const visible = [];
            const bounds = map.getBounds();
            if (!bounds) {
                return;
            }
            this.markerPoints.forEach((point) => {
                // eslint-disable-next-line no-undef
                if (bounds.contains(new google.maps.LatLng(point.lat, point.lng))) {
                    if (!point.id) {
                        return;
                    }
                    visible.push(point.id);
                }
            });
            eventBus.emit('googleMaps:zoomedToLocations', visible);
        },
        fitMap() {
            const map = this.getMap();
            if (map === null) {
                return;
            }
            const bounds = this.getBounds();
            if (this.markerPoints.length > 1) {
                map.fitBounds(bounds);
            }
            map.setCenter(bounds.getCenter());
        },
        getBounds() {
            // eslint-disable-next-line no-undef
            const bounds = new google.maps.LatLngBounds();
            this.markerPoints.forEach((point) => {
                // eslint-disable-next-line no-undef
                bounds.extend(new google.maps.LatLng(point.lat, point.lng));
            });
            return bounds;
        },
    },
    watch: {
        markerPoints() {
            this.fitMap();
        },
    },
});
